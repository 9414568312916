// Import jQuery 
//////////////////////////////////////////////////////////////////////
window.$ = window.jQuery = jQuery;

// Import Libraries
//////////////////////////////////////////////////////////////////////

// Utils
//////////////////////////////////////////////////////////////////////
import * as utils from './utils/utils.js'

// Require modules
//////////////////////////////////////////////////////////////////////
import cssVarsDynamic from './modules/css-vars-dynamic.js'
import browserClassHelpers from './modules/browser-class-helpers.js'
import header from './modules/header.js'
import footer from './modules/footer.js'
import register from './modules/register.js'
import navSecondary from './modules/nav-secondary.js'
import thematics from './modules/thematics.js'
import customFileInput from './modules/customFileInput.js'

// Components
//////////////////////////////////////////////////////////////////////
import events from './components/b14_events.js'
import b08_slider from './components/b08_slider.js'
import b13gridSlider from './components/b13gridSlider.js'

// Init all
//////////////////////////////////////////////////////////////////////
$(function(){

	$("html").removeClass("no-js");

	browserClassHelpers.init();
	
	window.onload = function(){
		cssVarsDynamic.init();
	}
	
	header.init();
	footer.init();

	// Nav secondary
	if (utils.exist('.navSecondary')) {
		navSecondary.init();
	}

	// Events
	if (utils.exist('.js-events-calendar')) {
		events.init();
	}

	// Join
	if (utils.exist('.js-b13gridSlider')) {
		b13gridSlider.init();
	}

	// Join
	if (utils.exist('.js-b08_slider')) {
		b08_slider.init();
	}

	// Register
	if (utils.exist('.js-register-tpl')) {
		register.init();
	}

	if(utils.exist('.js-daterange')){
		$('.js-daterange').each(function(e){
			$(this).daterangepicker({
				autoUpdateInput: false,
				locale: window.locale
			});
			$(this).on('apply.daterangepicker', function(ev, picker) {
				$(this).val(picker.startDate.format(locale.format) + ' - ' + picker.endDate.format(locale.format));
			});
		});
	}
	if(utils.exist('.js-daterange-monthYear')){
		$('.js-daterange-monthYear').each(function(e){
			let $this = $(this);
			let initVal = $this.val();
			$this.daterangepicker({
				showDropdowns: true,
				singleDatePicker: true,
				autoUpdateInput: true,
				locale: {
					format: "MMMM YYYY",
					cancelLabel: 'Clear',
					monthNames: [
						"January",
						"February",
						"March",
						"April",
						"May",
						"June",
						"July",
						"August",
						"September",
						"October",
						"November",
						"December"
					],
				}
			});

			$this.on('show.daterangepicker', function(ev, picker) {
				picker.container.addClass('monthly');
			});

			$this.on('hide.daterangepicker', function(ev, picker) {
				/*
				 * i selected the third row because there was month
				 * that date 1 on second row, so i feel to keep it save
				 * with choosing the third row
				 */
				var td = $(picker.container).find('.table-condensed tbody tr:nth-child(3) td:first-child');
				/*
				 * the setTimeout have on purpose to delay calling trigger
				 * event when choosing date on third row, if you not provide
				 * the timeout, it will throw error maximum callstack
				 */
				setTimeout(function() {
					/*
					 * on the newer version to pick some date was changed into event
					 * mousedown
					 */
					td.trigger('mousedown');
					/*
					 * this was optional, because in my case i need send date with
					 * starting day with 1 to keep backend neat
					 */
					picker.setStartDate(picker.startDate.date(1));
					picker.setEndDate(picker.endDate.date(1));
				}, 1);
			})

			// $(this).on('apply.daterangepicker', function(ev, picker) {
			// 	$(this).val(picker.startDate.format(locale.format) + ' - ' + picker.endDate.format(locale.format));
			// });

			// sinon initialise avec le mois actuel
			if(initVal == ''){
				$this.val('');
			}
			$this.on('cancel.daterangepicker', function(ev, picker) {
				setTimeout(function() {
					$this.val('');
				}, 10);
			});
		});
	}

	// Thematics
	if (utils.exist('.js-thematics')) {
		thematics.init();
	}

	$(".js-submitOnChange").on('change',function(){
		let $this = $(this);
		let $form = $this.closest('form');
		if(!$form.length){
			$form = $("#"+$this.attr('form'));
			if(!$form.length){
				return;
			}
		}
		$form.submit();
	});

	if (utils.exist('.js-customFileInput')) {
		customFileInput.init()
	}

	$(".js-sidebarMobile-section-fixed").each(function(){
		var $this = $(this);
		var $sidebar__content = $this.find(".js-sidebarMobile-section-content");
		$this.find(".js-section__title").on('click', function(){
		console.log($(this));
			$(this).toggleClass('--open');
			$sidebar__content.slideToggle();
		});
	})

	function ajaxResourceLink($resource_link){
		if($resource_link.length > 0){
			const id_resource_list = []
			for (var i = 0; i < $resource_link.length; i++) {
				id_resource_list.push($resource_link.eq(i).attr('data-id_resource'));
			}
			console.log('id_resource_list', id_resource_list);
			$.ajax({
				url: wordpress_vars.ajax_url,
				type: 'POST',
				data: {
					action: 'get_resource_link',
					current_url: window.location.href,
					params:{
						'id_resource_list': id_resource_list
					}
				},
				beforeSend: function(){
					$resource_link.addClass('--loading')
				},
				success: function(response) {
					if(response){
						const response_array = JSON.parse(response)
						for (var i = 0; i < response_array.length; i++) {
							if(response_array[i].html){
								const $target = $('.js-get_resource_link[data-id_resource="'+response_array[i].id+'"]');
								if($target){
									$target.html(response_array[i].html);
								}
							}
						}
					}
				},
				complete: function(){
					$resource_link.removeClass('--loading')
				},
				error: function(xhr, status, error) {
					console.error('AJAX Error:', status, error);
				}
			});
		}
	}
	// load resource link if session logged (avoid caching)
	const $resource_link = $('.js-get_resource_link');
	ajaxResourceLink($resource_link);

	// load flash if session logged (avoid caching)
	const $flash_content = $('.js-get_flash_content');
	$flash_content.each(function(){
		const $this_flash_content = $(this);
		const id_flash = $this_flash_content.attr('data-id_flash');
		console.log('id_flash', id_flash);
		$.ajax({
			url: wordpress_vars.ajax_url,
			type: 'POST',
			data: {
				action: 'get_flash_content',
				current_url: window.location.href,
				params:{
					'id_flash': id_flash
				}
			},
			beforeSend: function(){
				$this_flash_content.addClass('--loading')
			},
			success: function(response) {
				if(response){
					$this_flash_content.html(response);
					ajaxResourceLink($this_flash_content.find('.js-get_resource_link'));
				}
			},
			complete: function(){
				$this_flash_content.removeClass('--loading')
			},
			error: function(xhr, status, error) {
				console.error('AJAX Error:', status, error);
			}
		});
	});

	// load comma if session logged (avoid caching)
	const $comma_content = $('.js-get_comma_content');
	$comma_content.each(function(){
		const $this_comma_content = $(this);
		const id_comma = $this_comma_content.attr('data-id_comma');
		console.log('id_comma', id_comma);
		$.ajax({
			url: wordpress_vars.ajax_url,
			type: 'POST',
			data: {
				action: 'get_comma_content',
				current_url: window.location.href,
				params:{
					'id_comma': id_comma
				}
			},
			beforeSend: function(){
				$this_comma_content.addClass('--loading')
			},
			success: function(response) {
				if(response){
					$this_comma_content.html(response);
					ajaxResourceLink($this_comma_content.find('.js-get_resource_link'));
				}
			},
			complete: function(){
				$this_comma_content.removeClass('--loading')
			},
			error: function(xhr, status, error) {
				console.error('AJAX Error:', status, error);
			}
		});
	});

	// load comma link if session logged (avoid caching)
	const $comma_link = $('.js-get_comma_link');
	if($comma_link.length > 0){
		const id_comma_list = []
		for (var i = 0; i < $comma_link.length; i++) {
			id_comma_list.push($comma_link.eq(i).attr('data-id_comma'));
		}
		$.ajax({
			url: wordpress_vars.ajax_url,
			type: 'POST',
			data: {
				action: 'get_comma_link',
				current_url: window.location.href,
				params:{
					'id_comma_list': id_comma_list
				}
			},
			beforeSend: function(){
				$comma_link.addClass('--loading')
			},
			success: function(response) {
				if(response){
					const response_array = JSON.parse(response)
					for (var i = 0; i < response_array.length; i++) {
						if(response_array[i].html){
							const $target = $('.js-get_comma_link[data-id_comma="'+response_array[i].id+'"]');
							if($target){
								$target.html(response_array[i].html);
							}
						}
					}
				}
			},
			complete: function(){
				$comma_link.removeClass('--loading')
			},
			error: function(xhr, status, error) {
				console.error('AJAX Error:', status, error);
			}
		});
	}

	// check if logged to change access status
	const $authorisation = $('.js-get_authorisation');
	if($authorisation.length > 0){
		// pas besoin de le faire pour chaque, c'est juste le texte qui change, pas lié au poste
		$.ajax({
			url: wordpress_vars.ajax_url,
			type: 'POST',
			data: {
				action: 'get_authorisation',
				current_url: window.location.href,
				params:{}
			},
			beforeSend: function(){
				$authorisation.addClass('--loading')
			},
			success: function(response) {
				if(response){
					$authorisation.html(response);
				}
			},
			complete: function(){
				$authorisation.removeClass('--loading')
			},
			error: function(xhr, status, error) {
				console.error('AJAX Error:', status, error);
			}
		});
	}

});
